<template>
  <div
    id="app"
    :class="{ 'is-dark-theme': ($route.name || '').includes('ide') }"
  >
    <div id="modals">
      <ModalConfirmAction
        v-if="hasMounted"
        id="confirm-action-modal"
        ref="confirmActionModal"
      />
    </div>

    <router-view v-slot="{ Component, route }">
      <component
        :is="Component"
        :key="route.name"
      />
    </router-view>

    <GlobalToast />

    <LazyFinancialPendingModal
      v-if="hasFinancialPending"
      id="financial-pending"
    />
  </div>
</template>

<script>
import { defineComponent, ref, computed, defineAsyncComponent, watch } from 'vue'

import { mapActions, mapState } from 'vuex'

import components from './components'

const LazyFinancialPendingModal = defineAsyncComponent(() =>
  /* webpackChunkName: 'financial-pending-modal' */ import('@/layout/modals/FinancialPendingModal.vue')
)

export default defineComponent({
  name: 'App',

  components: {
    LazyFinancialPendingModal,
    ...components(
      'GlobalToast',
      'ModalConfirmAction'
    )
  },

  provide () {
    return {
      confirmActionModal: computed(() => this.confirmActionModal)
    }
  },

  setup () {
    const confirmActionModal = ref(null)

    return {
      confirmActionModal
    }
  },

  data () {
    return {
      hasMounted: false
    }
  },

  computed: {
    ...mapState('apps', [
      'app',
      'displayFinancialModal'
    ]),

    /**
     *
     * @returns {boolean}
     */
    hasFinancialPending () {
      return this.app.has_financial_pending
    }
  },

  mounted () {
    this.hasMounted = true

    this.emitter.on('modal-ready', (id) => {
      if (id !== 'financial-pending') return

      const nextShown = parseInt(localStorage.getItem('inc_next_shown_financial_modal') ?? 0)

      const diffBetweenShow = 8640E4

      const nextPresentation = Date.now() > nextShown

      if (nextPresentation) {
        localStorage.setItem('inc_next_shown_financial_modal', JSON.stringify(Date.now() + diffBetweenShow))
      }

      if (!this.app?.has_financial_pending || !nextPresentation) return

      this.emitter.emit('toggle-modal', id)
    })

    // watch(() => this.app, () => {
    //   const { app } = this
    //
    //   if (!app?.has_financial_pending) return
    //
    //   this.emitter.emit('toggleModalVisibility', true)
    // })
  },

  methods: {
    ...mapActions('apps', [
      'setDisplayFinancialModal'
    ])
  }
})
</script>

<style lang="scss">
  @import '@/style.scss';
</style>
